import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import {
  IOrderModel,
  IOrderGridModel,
  IMasterProductionScheduleModel,
} from "@models";

@Injectable()
export class MasterProductionScheduleService {
  constructor(private httpService: HttpService) {}

  async getAllOrdersForMasterProduction(): Promise<
    IMasterProductionScheduleModel[]
  > {
    return this.httpService.get(
      "/masterProductionSchedule/getAllOrdersForMasterProduction"
    );
  }

  async getOrderForMasterProduction(
    orderId: number
  ): Promise<IMasterProductionScheduleModel> {
    const params = {
      orderId: orderId,
    };

    return this.httpService.get(
      "/masterProductionSchedule/getOrderForMasterProduction",
      params
    );
  }

  async releaseToProduction(
    orderId: number
  ): Promise<IMasterProductionScheduleModel> {
    return this.httpService.patch(
      `/masterProductionSchedule/releaseToProduction?orderId=${orderId}`
    );
  }

  async removeFromProduction(
    orderId: number
  ): Promise<IMasterProductionScheduleModel> {
    return this.httpService.patch(
      `/masterProductionSchedule/removeFromProduction?orderId=${orderId}`
    );
  }

  async moveToShipping(
    orderId: number
  ): Promise<IMasterProductionScheduleModel> {
    return this.httpService.patch(
      `/masterProductionSchedule/moveToShipping?orderId=${orderId}`
    );
  }

  async moveToComplete(
    orderId: number,
    shippingCarrier: string,
    proNumber: string = ""
  ): Promise<IMasterProductionScheduleModel> {
    return this.httpService.patch(
      `/masterProductionSchedule/moveToComplete?orderId=${orderId}`,
      { shippingCarrier, proNumber }
    );
  }

  async changeProductionFacility(
    orderId: number,
    productionFacility: string
  ): Promise<IMasterProductionScheduleModel> {
    return this.httpService.patch(
      `/masterProductionSchedule/changeProductionFacility?orderId=${orderId}&productionFacility=${productionFacility}`
    );
  }
}
