export interface IOrderSpecs {
  orderSpecId: number;
  orderType: string;
  modelType: string;
  allInOneSystem?: boolean;
  unitLocation: string;
  poolType: string;
  guideType: string;
  excludeGuide?: boolean;
  encapsulationType: string;
  excludeEncapsulation?: boolean;
  boxPerimeterEncapsulation: string;
  includeClipOnCoping: string;
  clipOnCopingType: string;
  includeMatchingLidDescender: string;
  lidType: string;
  lidBrackets?: number;
  heavyDutyLidBrackets?: boolean;
  includeReusableCantileverDeckFormKit: string;
  housingType: string;
  housingMountingKit: string;
  motorSide: string;
  motorType: string;
  guideSpaceFeet?: number;
  guideSpaceInches?: number;
  guideLengthFeet?: number;
  guideLengthInches?: number;
  totalSqft: number;
  systemSqft: number;
  slack?: number;
  slackOverride?: boolean;
  rollerWrap?: number;
  rollerWrapOverride?: boolean;
  oem: string;
  fabricColor: string;
  webbingType: string;
  ropeOrLoop: string;
  leadingEdgeBarType: string;
  excludeLeadingEdgeBar?: boolean;
  rollupTubeType: string;
  excludeRollupTube?: boolean;
  sliderType: string;
  attachSlider?: boolean;
  bondWire?: boolean;
  ropeAttachment: string;
  coverOptions: string;
  cornerTypeNotes: string;
  vinylLinerRadiusCorner: string;
}

export class OrderSpecs implements IOrderSpecs {
  orderSpecId: number;
  orderType: string;
  modelType: string;
  allInOneSystem?: boolean;
  unitLocation: string;
  poolType: string;
  guideType: string;
  excludeGuide?: boolean;
  encapsulationType: string;
  excludeEncapsulation?: boolean;
  boxPerimeterEncapsulation: string;
  includeClipOnCoping: string;
  clipOnCopingType: string;
  includeMatchingLidDescender: string;
  lidType: string;
  libBrackets?: number;
  heavyDutyLidBrackets?: boolean;
  includeReusableCantileverDeckFormKit: string;
  housingType: string;
  housingMountingKit: string;
  motorSide: string;
  motorType: string;
  guideSpaceFeet?: number;
  guideSpaceInches?: number;
  guideLengthFeet?: number;
  guideLengthInches?: number;
  totalSqft: number;
  systemSqft: number;
  slack?: number;
  slackOverride?: boolean;
  rollerWrap?: number;
  rollerWrapOverride?: boolean;
  oem: string;
  fabricColor: string;
  webbingType: string;
  ropeOrLoop: string;
  leadingEdgeBarType: string;
  excludeLeadingEdgeBar?: boolean;
  rollupTubeType: string;
  excludeRollupTube?: boolean;
  sliderType: string;
  attachSlider?: boolean;
  bondWire?: boolean;
  ropeAttachment: string;
  coverOptions: string;
  cornerTypeNotes: string;
  vinylLinerRadiusCorner: string;

  constructor() {
    this.allInOneSystem = false;
    this.slackOverride = false;
    this.rollerWrap = 3;
    this.rollerWrapOverride = false;
    this.attachSlider = true;
    this.bondWire = false;
  }
}
