import { IAddressModel, AddressModel } from "../contacts/address.model";

export interface ICompanyModel {
  companyId: number;
  parentCompanyId: number;
  billingAddressId: number;
  shippingAddressId: number;
  priceLevelId: number;
  sapCode: string;
  companyName: string;
  firstName: string;
  lastName: string;
  adminUserName: string;
  phoneNumber: string;
  secondaryPhone: string;
  emailAddress: string;
  orderConfirmationEmailAddresses: string;
  isShippingSameAsBilling: boolean;
  creditHold: boolean;
  terms: string;
  bcCustomerPriceGroup: string;
  bcPaymentTermCode: string;
  bcCode: string;
  bcEntity: string;
  active: boolean;

  billingAddress: IAddressModel;
  shippingAddress: IAddressModel;
}

export class CompanyModel implements ICompanyModel {
  companyId: number;
  parentCompanyId: number;
  billingAddressId: number;
  shippingAddressId: number;
  priceLevelId: number;
  sapCode: string;
  companyName: string;
  firstName: string;
  lastName: string;
  adminUserName: string;
  phoneNumber: string;
  secondaryPhone: string;
  emailAddress: string;
  bcCustomerPriceGroup: string;
  bcPaymentTermCode: string;
  bcCode: string;
  bcEntity: string;
  orderConfirmationEmailAddresses: string;
  isShippingSameAsBilling: boolean;
  creditHold: boolean;
  terms: string;
  active: boolean;

  billingAddress: IAddressModel;
  shippingAddress: IAddressModel;

  public static getNewCompanyModel(): ICompanyModel {
    const newCompanyModel = new CompanyModel();
    newCompanyModel.companyId = 0;
    newCompanyModel.parentCompanyId = 1;
    newCompanyModel.priceLevelId = 1;
    newCompanyModel.billingAddressId = 0;
    newCompanyModel.billingAddress = AddressModel.getNewAddressModel(true);
    newCompanyModel.isShippingSameAsBilling = true;
    newCompanyModel.shippingAddressId = null;
    newCompanyModel.shippingAddress = null;
    newCompanyModel.creditHold = false;
    newCompanyModel.terms = "PrePay";
    newCompanyModel.active = true;

    return newCompanyModel;
  }
}
