import { Injectable } from "@angular/core";
import { HttpService } from "./utils/http.service";
import {
  CompanyModel,
  ICompanyModel,
  ICompanyFromBCInfoModel,
  ICompanyInfoModel,
  IDropdownModel,
} from "@models";

@Injectable()
export class CompaniesService {
  constructor(private httpService: HttpService) {}

  async getAllCompaniesForGrid(
    showInactive: boolean
  ): Promise<ICompanyInfoModel[]> {
    return this.httpService.get("/companies/getAllCompaniesForGrid", {
      showInactive: showInactive,
    });
  }

  async getCompanyForGrid(companyId: number): Promise<ICompanyInfoModel> {
    return this.httpService.get("/companies/getCompanyForGrid", {
      companyId: companyId,
    });
  }

  async getCompany(companyId: number): Promise<ICompanyModel> {
    return this.httpService.get("/companies/getCompany", {
      companyId: companyId,
    });
  }

  async getCompanyBySAPCode(sapCode: string): Promise<ICompanyInfoModel> {
    return this.httpService.get("/companies/GetCompanyBySAPCode", {
      sapCode: sapCode,
    });
  }

  async getAllCompaniesForDropdown(
    showInactive: boolean
  ): Promise<IDropdownModel[]> {
    const params = {
      showInactive: showInactive,
    };

    return this.httpService.get(
      "/companies/getAllCompaniesForDropdown",
      params
    );
  }

  async createNewCompany(companyModel: ICompanyModel): Promise<ICompanyModel> {
    return this.httpService.post("/companies/createNewCompany", companyModel);
  }

  async updateCompany(companyModel: ICompanyModel): Promise<ICompanyModel> {
    return this.httpService.post("/companies/updateCompany", companyModel);
  }

  async getCompanyDetailFromBC(
    bcEntity,
    companyNumber
  ): Promise<ICompanyFromBCInfoModel> {
    return this.httpService.get("/companies/getBCCompanyInfo", {
      bcEntity: bcEntity,
      companyNumber: companyNumber,
    });
  }
}
